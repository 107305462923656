import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as account from '../../db/account';
import * as balance from '../../db/balance';
import type { Account } from '../types';
import { RootState } from '../store';
import { AppDispatch } from '../useAppDispatch';

const NAMESPACE = 'account';

type ThunkApi = { state: RootState, dispatch: AppDispatch }

export const getAll = createAsyncThunk<Account[], void, ThunkApi>(
  `${NAMESPACE}/getAll`,
  async (_, _thunkApi) => {
    const accountEntities = await account.getAll();

    const accounts: Account[] = accountEntities.map((x, i) => {
      const dateSortedBalances = x.balances
        .sort((a, b) => {
          return b.date.getTime() - a.date.getTime();
        })
        .map((x) => ({
          id: x.id,
          amount: x.amount,
          date: x.date.toISOString(),
        }));

      const currentBalance = dateSortedBalances[0]?.amount || 0;

      const existingAccount = _thunkApi.getState().accounts.accounts.find(a => a.id === x.id)

      return {
        id: x.id,
        name: x.name,
        currentBalance,
        balances: dateSortedBalances,
        sortOrder: existingAccount?.sortOrder ?? i
      };
    });

    return accounts;
  }
);

export const removeAccount = createAsyncThunk<void, number, ThunkApi>(
  `${NAMESPACE}/removeAccount`,
  async (id, thunkApi) => {
    await account.remove(id);
    thunkApi.dispatch(getAll());
  }
);

export const removeBalance = createAsyncThunk<void, number, ThunkApi>(
  `${NAMESPACE}/removeBalance`,
  async (id, thunkApi) => {
    await balance.remove(id);
    thunkApi.dispatch(getAll());
  }
);

export const addAccount = createAsyncThunk<void, string, ThunkApi>(
  `${NAMESPACE}/addAccount`,
  async (name, thunkApi) => {
    await account.add(name);
    thunkApi.dispatch(getAll());
  }
);

export const addBalance = createAsyncThunk<
  void,
  { amount: number; id: number; date: Date },
  ThunkApi
>(`${NAMESPACE}/addBalance`, async ({ amount, id, date }, thunkApi) => {
  await balance.add(amount, id, date);
  thunkApi.dispatch(getAll());
});

export const rename = createAsyncThunk<
  void,
  { id: number; name: string },
  ThunkApi
>(`${NAMESPACE}/rename`, async ({ id, name }, thunkApi) => {
  await account.updateName(id, name);
  thunkApi.dispatch(getAll());
});

export const accountSlice = createSlice({
  name: NAMESPACE,
  initialState: {
    accounts: [] as Account[],
    loading: false,
  },
  reducers: {
    updateSortOrder: (state, action: PayloadAction<{ id: number, sortOrder: number }[]>) => {
      state.accounts.forEach(x => {
        const changeExists = action.payload.find(a => a.id === x.id)
        if (changeExists) {
          x.sortOrder = changeExists.sortOrder
        }
      })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.accounts = action.payload;
        state.loading = false;
      })
      .addCase(getAll.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAll.rejected, (state) => {
        state.loading = false;
      })
      .addCase(removeAccount.fulfilled, (state, _) => {
        state.loading = false;
      })
      .addCase(removeAccount.pending, (state, _) => {
        state.loading = true;
      })
      .addCase(removeAccount.rejected, (state, _) => {
        state.loading = false;
      })
      .addDefaultCase((state) => {
        state.loading = false;
      });
  },
});
